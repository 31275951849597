export const pirateCollectionId = "game.piratenation.piratenft";
export const shipsCollectionId = "game.piratenation.tradeablegameitems";
export const gameItemsCollectionId = "game.piratenation.tradeableshipnft";

export const collectionIds = { pirates: pirateCollectionId, ships: shipsCollectionId, items: gameItemsCollectionId };

export const CDN_GATEWAY_URL = 'https://ipfs.proofofplay.gg/ipfs/'
export const IPFS_GATEWAY_URL = 'https://ipfs.io/ipfs/'

export const GAME_URL = process.env.NEXT_PUBLIC_BASE_URL ?? 'piratenation.game'
