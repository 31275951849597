import Link from 'next/link'
import { styled } from '@stitches/react'
import { useMediaQuery } from 'react-responsive'
import { useCollections } from '@reservoir0x/reservoir-kit-ui'
import { useState } from 'react'

import LoadingCard from 'components/common/LoadingCard'
import Card from 'components/custom/Card'
import supportedChains from 'utils/chains'
import { CDN_GATEWAY_URL } from 'utils/constants'
import { Button } from 'components/primitives'

import ChainCollection from './ChainCollection'
import { getChainById, getKeyByContractId } from 'utils/collection'
import { getPopChainById } from 'utils/multichain'

function parseCollectionString(inputString: string) {
  const prefix = 'Pirate Nation - '
  const startIndex = inputString.indexOf(prefix)

  if (startIndex !== -1) {
    return inputString.substring(startIndex + prefix.length)
  } else {
    return inputString
  }
}

type Collection = {
  collection: ReturnType<typeof useCollections>['data']
}

type CollectionGridProps = {
  collections: Collection[]
}
// NOTE: Since we are accessing via two chains, can't fetch dynamic token media via contract and id
const ipfsTokenMedia = [
  'QmQYSdcxKKkWcSb2jMiii6nY5x67gsifRxZihMuSH3EEem/3950',
  'QmaxrVd4yAmyhrdAEnwmpABvCUxxscud3jKoqLWjSvCoXU',
  'QmahJfRA4e8EmDHLfqUny8ipSBxbH8w6Kh4hmEcvGcQkA5',
]

const GridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  '@media (max-width: 975px)': {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
  gap: '20px',
  width: '100%',
  height: '100%',
  paddingLeft: 0,
  boxSizing: 'border-box',
})

const CollectionGrid = ({ collections }: CollectionGridProps) => {
  const [isMultiChainModalOpen, setIsMultiChainModalOpen] =
    useState<boolean>(false)
  const [selectedCollectionSystemId, setSelectedCollectionSystemId] =
    useState<string>('')

  return (
    <>
      <GridContainer>
        {collections.length > 0
          ? collections.map((collection: any, idx: number) => {
              const chain = supportedChains.find(
                (chain) => chain.id === collection?.chainId
              )

              const collectionName = parseCollectionString(collection?.name)

              const hasMultichainCollection = Boolean(
                getPopChainById(collection?.chainId)
              )

              return hasMultichainCollection ? (
                <ChainCollection
                  key={`collection-${collection?.id}-${collectionName}`}
                  trigger={
                    <Button
                      color="ghost"
                      size="none"
                      css={{ textAlign: 'left', lineHeight: 'inherit' }}
                      onClick={() => {
                        setIsMultiChainModalOpen(true)

                        setSelectedCollectionSystemId(collection?.id)
                      }}
                    >
                      <Card
                        imageIPFS={`${CDN_GATEWAY_URL}${ipfsTokenMedia[idx]}`}
                        title={collectionName}
                        numOfListings={Number(`${collection?.onSaleCount}`)}
                      />
                    </Button>
                  }
                  open={isMultiChainModalOpen}
                  onOpenChange={(open) => setIsMultiChainModalOpen(open)}
                  collectionSystemId={selectedCollectionSystemId ?? ''}
                />
              ) : (
                <Link
                  key={`collection-${collection?.id}`}
                  href={`/nft/${getKeyByContractId(collection?.id)}?chain=${
                    getChainById(chain?.id)?.id
                  }`}
                >
                  <Card
                    imageIPFS={`${CDN_GATEWAY_URL}${ipfsTokenMedia[idx]}`}
                    title={collectionName}
                    numOfListings={Number(`${collection?.onSaleCount}`)}
                  />
                </Link>
              )
            })
          : Array(3)
              .fill(null)
              .map((_, index) => <LoadingCard key={`loading-card-${index}`} />)}
      </GridContainer>
    </>
  )
}

export default CollectionGrid
