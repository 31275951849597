import { Chain, chains, nfts } from "config/routes"

export const getChainsByContractId = (
    contractId: string
): Record<string, string> | null => {
    for (const nft of Object.values(nfts)) {
        for (const [chain, address] of Object.entries(nft.chains)) {
            if (address.toLowerCase() === contractId.toLowerCase()) {
                // Found the NFT, return its chains object
                return nft.chains
            }
        }
    }
    return null
}

export const getKeyByContractId = (
    contractId: string
): string => {
    for (const [key, nft] of Object.entries(nfts)) {
        for (const address of Object.values(nft.chains)) {
            if (address.toLowerCase() === contractId.toLowerCase()) {
                return key
            }
        }
    }
    return ''
}

export const getChainById = (chainId: number | undefined): Chain | null => {
    return Object.values(chains).find(chain => chain.chainId === chainId) ?? null;
}