import { useCollections } from '@reservoir0x/reservoir-kit-ui';
import { popChains, chains, nfts } from 'config/routes';
import { getChainsByContractId, getKeyByContractId } from 'utils/collection';
import { collectionIds } from 'utils/constants';

const fetchCollections = (contractIds: string[], chainKey: string) => {
    const { data } = useCollections(
        {
            contract: contractIds,
        },
        {
            revalidateOnMount: true,
            refreshInterval: 300000,
        },
        chains?.[chainKey]?.chainId
    );
    return data || [];
};

const aggregateCollections = (collections: any[]) => {
    return collections.reduce((acc, current) => {
        const existingItem = acc.find((item: any) => item?.name === current?.name);
        if (existingItem) {
            existingItem.onSaleCount += Number(current?.onSaleCount);
        } else {
            acc.push({
                ...current,
                name: current.name,
                onSaleCount: Number(current?.onSaleCount),
            });
        }
        return acc;
    }, []);
};

export const getAllChainCollections = () => {
    const popNFTChains = Object.entries(chains);

    const collectionsList = popNFTChains.map(([chainKey]) => {
        return fetchCollections(
            [
                nfts?.[collectionIds.pirates]?.chains?.[chainKey],
                nfts?.[collectionIds.ships]?.chains?.[chainKey],
                nfts?.[collectionIds.items]?.chains?.[chainKey]
            ].filter(Boolean),
            chainKey
        );
    });

    // Flatten the array of arrays and aggregate as necessary
    const flattenedCollections = collectionsList.flat();
    return aggregateCollections(flattenedCollections);
};


export const getMultiChainCollections = (collectionSystemId: string) => {
    const popNFTChains = Object.entries(getChainsByContractId(collectionSystemId) ?? {});
    const collectionKey = getKeyByContractId(collectionSystemId);

    const collectionsList = popNFTChains.map(([chainKey]) => {
        return fetchCollections(
            [nfts?.[collectionKey]?.chains?.[chainKey]].filter(Boolean),
            chainKey
        );
    });
    return collectionsList.flat();

};

export const getPopChainById = (chainId: number) => {
    return Object.values(popChains).find((chain) => chain.chainId === chainId);
};
