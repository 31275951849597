import { styled } from '@stitches/react'

import Show from 'components/custom/effects/Show'
import { Box, Text } from 'components/primitives'
import { CDN_GATEWAY_URL } from 'utils/constants'

const StyledCard = styled('div', {
  display: 'flex',
  minWidth: '155px',
  flexDirection: 'column',
  borderRadius: 8,
  overflow: 'hidden',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  backgroundColor: '$purple600',
  '&:hover': {
    transform: 'scale(1.05)',
  },
  transition: 'transform 0.2s ease-in-out',
})

const CardImage = styled('img', {
  width: '100%',
  height: '60%',
  objectFit: 'contain',
})

const CardContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '24px $4',
  gap: '$4',
})

type CardProps = {
  title: string
  numOfListings: number
  imageIPFS: string
}

const Card: React.FC<CardProps> = ({ title, numOfListings, imageIPFS }) => {
  return (
    <StyledCard>
      <CardImage src={imageIPFS} alt={title} />
      <CardContent>
        <Box
          css={{
            display: 'block',
            '@bpMax975': {
              display: 'none',
            },
          }}
        >
          <Text css={{ color: '#A19EFF', fontSize: 16, fontWeight: 600 }}>
            Pirate Nation
          </Text>
        </Box>
        <Show
          css={{
            display: 'none',
            '@bpMax975': {
              display: 'block',
            },
          }}
        >
          <Text style="cardSubtitle" css={{ fontWeight: '600' }}>
            {title}
          </Text>
        </Show>
        <Show
          css={{
            display: 'block',
            '@bpMax975': {
              display: 'none',
            },
          }}
        >
          <Text style="cardTitle" css={{ fontWeight: '600' }}>
            {title}
          </Text>
        </Show>
        <Text style="cardSubtitle">{numOfListings} Listings</Text>
      </CardContent>
    </StyledCard>
  )
}

export default Card
