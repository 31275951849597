import Link from 'next/link'
import { Dialog } from 'components/primitives/Dialog'
import { Button, Flex, Text } from 'components/primitives'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

import { popChains } from 'config/routes'
import React from 'react'
import { getKeyByContractId } from 'utils/collection'
import { getMultiChainCollections } from 'utils/multichain'

const ChainCollection = ({
  open,
  onOpenChange,
  collectionSystemId,
  trigger,
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
  collectionSystemId: string
  trigger: React.ReactElement
}) => {
  const collectionKey = getKeyByContractId(collectionSystemId)
  const allChains = getMultiChainCollections(collectionSystemId)

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={onOpenChange}
        overlayProps={{ style: { opacity: 0.7 } }}
        trigger={trigger}
      >
        <AnimatePresence>
          <Flex
            css={{
              px: 25,
              py: 16,
              backgroundColor: '$darkGray400',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Text style="subtitle1">Select Chain</Text>
            <DialogPrimitive.Close asChild>
              <Button color="ghost" size="none" css={{ color: '$neutralText' }}>
                <FontAwesomeIcon icon={faClose} width={24} height={24} />
              </Button>
            </DialogPrimitive.Close>
          </Flex>
          <Flex
            direction="column"
            css={{ p: 24, gap: '$4', background: '$neutralBg' }}
          >
            <Flex
              css={{ gap: '$2', mt: '$2' }}
              direction="column"
              justify="end"
            >
              {allChains?.map((chain) => {
                const popChain = Object.values(popChains).find(
                  (popChain) => popChain.chainId === chain.chainId
                )

                return (
                  <Link href={`/nft/${collectionKey}?chain=${popChain?.id}`}>
                    <Button
                      corners="rounded"
                      size="large"
                      css={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        background: '$neutralBgContent',
                        '&:hover': {
                          background: 'rgba(255, 255, 255, 0.04)',
                        },
                      }}
                    >
                      <Text css={{ fontWeight: 600, fontSize: 16 }}>
                        <div
                          style={{
                            display: 'flex',
                            gap: '12px',
                            width: '100%',
                          }}
                        >
                          <span>{`${popChain?.name} Chain`}</span>
                          <span>{chain?.onSaleCount} Listings</span>
                        </div>
                      </Text>
                    </Button>
                  </Link>
                )
              })}
            </Flex>
          </Flex>
        </AnimatePresence>
      </Dialog>
    </>
  )
}

export default ChainCollection
